import { useRouter } from 'next/router';
import { NextSeo, NextSeoProps } from 'next-seo';
import React from 'react';

import { BASE_URL } from 'src/config/generic';

export const Seo: React.FC<NextSeoProps> = ({ title, description, ...props }) => {
	const router = useRouter();
	const path = router.asPath;
	const url = `${BASE_URL}${path}`;

	return (
		<NextSeo
			title={title}
			description={description}
			openGraph={{ url, title, description }}
			twitter={{ site: url }}
			{...props}
		/>
	);
};
