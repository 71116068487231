import React from 'react';

import { Layout } from 'src/components/layout/Layout';
import { LayoutColumn } from 'src/components/layout/LayoutColumn';
import { Seo } from 'src/components/next/Seo';

export interface Meta {
	title: string;
	description?: string;
}

interface PageTemplateProps extends React.PropsWithChildren {
	meta: Meta;
}

export const PageTemplate: React.FC<PageTemplateProps> = ({ children, meta, ...props }) => (
	<>
		<Seo {...meta} />
		<Layout {...props}>
			<LayoutColumn>{children}</LayoutColumn>
		</Layout>
	</>
);
