import { Box, Stack } from '@mui/material';
import type { NextPage } from 'next';
import React from 'react';

import { Link } from 'src/components/next/Link';
import { NextImage } from 'src/components/next/NextImage';
import { PageTemplate } from 'src/templates/PageTemplate';

const StackItem: React.FC<React.PropsWithChildren> = ({ children }) => (
	<Box component="div" sx={{ width: '100%' }}>
		{children}
	</Box>
);

const HomePage: NextPage = () => (
	<PageTemplate meta={{ title: 'Medianique', description: 'Software Engineering' }}>
		<Stack spacing={2} alignItems="center">
			<StackItem>
				<Link href="/projecten/radio-veronica">
					<NextImage
						src="/assets/projecten/radio-veronica/veronica-app-headphones-iphone11-pro-80s-top750.jpg"
						alt="Radio Veronica app"
						quality={100}
						priority
					/>
				</Link>
			</StackItem>
			<StackItem>
				<Link href="/projecten/aladinfm-idisplays">
					<NextImage
						src="/assets/projecten/aladinfm-idisplays/idisplay-bayer.jpg"
						alt="Bayer iDisplays"
						quality={100}
					/>
				</Link>
			</StackItem>
			<StackItem>
				<Link href="/projecten/zapp-fm">
					<NextImage src="/assets/projecten/zapp-fm/zapp-promo-01.jpg" alt="Zapp FM" />
				</Link>
			</StackItem>
			<StackItem>
				<Link href="/projecten/selectives-platform">
					<NextImage
						src="/assets/projecten/selectives-platform/de-volkskrant-edition-ipad-no-shadow.png"
						quality={100}
						alt="Selectives Platform"
						background={false}
					/>
				</Link>
			</StackItem>
			<StackItem>
				<Link href="/projecten/kifid">
					<NextImage src="/assets/projecten/kifid/kifid-teaser.png" quality={100} alt="KiFiD" />
				</Link>
			</StackItem>
			<StackItem>
				<Link href="/projecten/gfk-performance-pulse">
					<NextImage
						src="/assets/projecten/gfk-performance-pulse/gfk-teaser.png"
						quality={100}
						alt="GfK Performance Pulse"
					/>
				</Link>
			</StackItem>
			<StackItem>
				<Link href="/projecten/sky-radio">
					<NextImage
						src="/assets/projecten/sky-radio/sky-radio-teaser.png"
						quality={100}
						alt="Sky Radio"
					/>
				</Link>
			</StackItem>
			<StackItem>
				<Link href="/projecten/identity-design">
					<NextImage
						src="/assets/projecten/identity-design/table-of-eight-teaser.png"
						quality={100}
						alt="Table of Eight"
					/>
				</Link>
			</StackItem>
			<StackItem>
				<Link href="/projecten/identity-design-abb-elux">
					<NextImage
						src="/assets/projecten/identity-design/abb-elux/abb-elux-family.png"
						quality={100}
						alt="ABB Elux"
					/>
				</Link>
			</StackItem>
			<StackItem>
				<Link href="/projecten/tm-digital">
					<NextImage
						src="/assets/projecten/tm-digital/tm-digital_ipad-mini.jpg"
						quality={90}
						alt="TM Digital app"
					/>
				</Link>
			</StackItem>
			<StackItem>
				<Link href="/projecten/map-your-city">
					<NextImage
						src="/assets/projecten/map-your-city/map_your_city_promo.jpg"
						quality={90}
						alt="Map Your City"
					/>
				</Link>
			</StackItem>
			<StackItem>
				<Link href="/projecten/zapp-fm">
					<NextImage
						src="/assets/projecten/zapp-fm/zapp-apparel-02.png"
						quality={100}
						alt="Zapp FM"
					/>
				</Link>
			</StackItem>
			<StackItem>
				<Link href="https://beauchart.com">
					<NextImage
						src="/assets/projecten/beauchart/beauchart-teaser.png"
						quality={100}
						alt="Beauchart - Knots to Beaufort Plus More"
					/>
				</Link>
			</StackItem>
		</Stack>
	</PageTemplate>
);

export default HomePage;
